
/*
=============== 
Variables
===============
*/

:root {

  /*background color options: Peter*/
    --clr-bg-primary-1: hsl(26, 49%, 84%);
    --clr-bg-primary-2: hsl(350, 100%, 98%);
    --clr-bg-primary-3: hsl(0, 5%, 65%);
    --clr-bg-primary-4: hsl(27, 80%, 83%);
    --clr-bg-primary-5: hsl(347, 35%, 89%);
    --clr-bg-primary-6: hsl(0, 9%, 19%);

  /*font colors options: Peter*/
    --clr-primary-heading: hsl(279, 11%, 29%);
    --clr-secondary-heading: hsl(356, 17%, 33%);
    --clr-primary-text: hsl(182, 20%, 50%);
    --clr-secondary-text: hsl(358, 82%, 70%);
    --clr-highlighter-text: hsl(358, 83%, 71%);

  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
    --ff-primary: 'JetBrains Mono', cursive;
      --ff-secondary: 'JetBrains Mono', cursive;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--ff-primary);
  background: var(--clr-bg-primary-2);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  font-weight: 400
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}




/*
=============== 
Header
===============
*/


header {
  background: var(--clr-white);
  box-shadow: var(--light-shadow);
  display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  
}
.title h2, h3, h4 {
  margin-bottom: 0;
  color: var(--clr-primary-heading)
}


          /*
=============== 
Login component
===============
*/

.login-section {
  
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
  
}

.login-btn {
  border: 05px;
  background: var(--clr-white);
  border-radius: 3px;
    box-shadow: 0 5px 10px rgba(107, 73, 73, 0.1);
    padding: 4px 5px;
    display: flex;
    flex-wrap: nowrap;
    font-size: 22px;
    align-items: center;

}

.login-btn:hover,
.login-btn:focus {
  background-color: var(--clr-grey-9);
  cursor: pointer
}
.login-btn .logo {
  margin-right: 10px;
}
.login-btn p {
  margin-bottom: 0px;
}


/*
=============== 
Error component
===============
*/
          
.error-section  {
display: flex;
align-content: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

color: var(--clr-red-light)

}
.error-section h3{
color: var(--clr-red-light);
margin-bottom: 0;
}
.error-section .logo {
  width: 50px
}

          /*
=============== 
Loading component
===============
*/


.letter-holder {
  margin: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.letter {
  float: left;
  font-size: 26px;
  color: var(--clr-primary-4);
}

.load-message .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}

.l-2 {
  animation-delay: 0.6s;
}

.l-3 {
  animation-delay: 0.72s;
}

.l-4 {
  animation-delay: 0.84s;
}

.l-5 {
  animation-delay: 0.96s;
}

.l-6 {
  animation-delay: 1.08s;
}

.l-7 {
  animation-delay: 1.2s;
}

.l-8 {
  animation-delay: 1.32s;
}

.l-9 {
  animation-delay: 1.44s;
}

.l-10 {
  animation-delay: 1.56s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


     /*
  =============== 
  Footer
  ===============
  */
  
footer {
background: var(--clr-white);
box-shadow: var(--light-shadow);
position: relative;
padding: 5rem 1rem;
display: flex;
justify-content: center;
align-items: center;
}

footer p {
color: var(--clr-grey-4);
text-transform: capitalize;
letter-spacing: var(--spacing);
margin-bottom: 0;
}

.roboteria-logo {
width: 40px;
  
}

main {
padding:1vh 1vw
}

          /*
=============== 
Widgets
===============
*/

.widget-section {
  display: flex;
  justify-content: space-around;
  margin: 3vh 4vw
}

.widget {
  display:flex;
  flex-direction:column;
  background: var(--clr-white);
  box-shadow: 0 5px 10px rgba(107, 73, 73, 0.1);
  padding: 1vh 1vw
}

.widget:hover,
.widget:focus {
  box-shadow: 0 0.9em 0.9em -0.1em rgba(107, 73, 73, 0.1);
  background-color: var(--clr-grey-9);
  transform: translateY(-0.17em);
  cursor: pointer
}

.selected {
  background-color: var(--clr-grey-7)
}

.widget-title {
  margin: 1vh 0 2vh 0
}



    /*
  =============== 
   Charts
  ===============
  */

.charts-section {
  display: flex;
  flex-direction: column;
  margin: 8vh 10vw
}

.chart-area text{
  font-size: 16;
}

.custom-tooltip {
  background-color: whitesmoke;
  border: solid #222 0.5px;
  padding: 5px
}

.custom-tooltip .wrapper {
  background-color: rgb(190, 29, 29);

}

.custom-tooltip p {
 margin:0
}

.chart-area {
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 10vh;
}

.pie-chart-area {
  display: inline-block;
 text-align: center;
 margin: 4vh 0 6vh
}

.pie-chart-area strong {
  margin-bottom: 0;
  color: var(--clr-primary-heading)
}

/* Custom styles for Ag-Grid with Material theme */
.ag-theme-material {
  --ag-background-color: var(--clr-bg-primary-2) !important;
  /* Use !important to override Ag-Grid styles */
  font-family: var(--ff-primary) !important;
  /* Use !important to override Ag-Grid styles */
}







